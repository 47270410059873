@import url("https://fonts.googleapis.com/css?family=Montserrat&display=swap");

body {
  margin: 0px;
  padding: 0px;
  background-color: var(--background-color);
  font-family: "Montserrat", Helvetica, Arial, sans-serif;
  overflow-x: hidden;
}

#root {
  margin: auto;
  /* background: blue !important; */
}

.responsive-image {
  width: 100%;
  height: auto;
}

iframe, #hide-control {
  width: 100%;
  height: 72vh;
  border: none;
}

#hide-control {
  position: absolute;
}

.hidden {
  display: none;
}

.clean {
  clear: both;
}

.col-4 {
  width: 33.3333%;
  float: left;
}

/* @media screen and (max-width: 860px) {
  iframe{
    height: 70vh;
  }
}

@media screen and (max-width: 400px) {
  iframe{
    height: 60vh;
  }
}

@media screen and (max-height: 800px) {
  iframe{
    height: 68vh;
  }
} */