div.text-input--box {
  display: flex;
  flex-direction: row;
  border: 1px solid black;
  padding: 5px;
}

div.text-input--container {
  margin-bottom: 8px;
}

div.title-section {
  display: flex;
  flex: 1;
}

h4.text-field--title {
  margin: 0;
  margin-left: 5px;
  font-size: 16px;
  font-weight: normal;
  color: gray;
}

div.text-input--section {
  display: flex;
  flex: 1;
}

input.text-input--field {
  flex: 1;
  outline: none;
  width: 0px;
  border: none;
  text-align: center;
  font-weight: bold;
}

h4.vertical-separator {
  margin: 0px;
  font-size: 16px;
  color: black;
  font-weight: normal;
}

h6.error-message {
  margin: 0;
  margin-top: 3px;
  color: red;
}

div.input-container {
  display: flex;
  align-items: center;
}

.custom-input {
  border-right: none;
  border-bottom: none;
  border-top: none;
  text-align: center;
}

.custom-input:focus-visible {
  outline: none;
}

div.mm {
  font-size: 12px;
  font-weight: bold;
}

.disabled > * {
  cursor: not-allowed !important;
  color: gray;
}
