.group-buttons {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 10px;
}

.group-input, .group-input-25 {
  display: grid;
  grid-gap: 10px;
  margin: 12px;
}

.group-input {
  grid-template-columns: repeat(2, 1fr);
}

.group-input-25 {
  grid-template-columns: 25% 1fr;
  align-items: center;
}

.opening-dimensions{
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.split_divider {
  margin: 12px;
}

.split_divider input{
  margin: 3px 5px 0 0;
}