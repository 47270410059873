.placeholder {
  margin: 0 auto;
  width: 100%;
  background-color: #eee;
  border-radius: 10px;
}

@keyframes movingGradient {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}

.animated-background {
  border-radius: 10px;
  animation-duration: 1.25s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: movingGradient;
  animation-timing-function: linear;
  background: linear-gradient(to right, #eeeeee 10%, #dddddd 18%, #eeeeee 33%);
  background-size: 800px 104px;
  height: 30px;
  position: relative;
}
