div#parameters-container {
  padding: 45px 5px 0px 0px;
  /* background: blue; */
  max-width: 380px;
  min-height: 75vh;
  height: 100%;
  margin: auto;
  overflow-y: auto;
  overflow-x: hidden;
}

div.parameters-horizontal-line {
  height: 1px;
  width: 100%;
  background: #949494;
  margin-bottom: 20px;
  margin-top: 0px;
}

.last-parameter {
  margin-bottom: 140px !important;
}

@media all and (min-width: 860px) {
  div#parameters-container {
    padding-top: 0px;
    margin: 0;
    height: calc(70vh - 30px - 16px - 9px);
    max-width: none;
    margin-bottom: 0px;
    min-height: initial;
  }
}

h3.parameters-section--title {
  font-weight: normal;
}

@media all and (max-width: 859px) {
  div#parameters-container {
    padding: 2px;
  }
}