div.select-title--section {
  display: flex;
  flex-direction: row;
  margin-bottom: 5px;
  /*background: purple;*/
}

h4.select-title {
  margin: 0;
  margin-left: 5px;
  font-size: 15px;
  font-weight: normal;
}

div.color-box {
  width: 13px;
  height: 13px;
  display: inline-block;
  vertical-align: middle;
  margin-right: 5px;
  border: 2px solid black;
}

div.color-container {
  white-space: nowrap;
  /*background: purple;*/
}

div.color-name {
  white-space: nowrap;
  display: inline-block;
  vertical-align: middle;
  font-size: 13px;
}

div.select-colors-box--container {
  margin-bottom: 0px;
}
