:root {
  --max-width: 1300px;
  --main-color: #33426c;
  --background-color: #ffffff;
  --app-container-color: #ffffff;
  --yellow: #ffed00;
}

#root {
  width: auto;
  max-width: var(--max-width);
  height: auto;
  background-color: white;
  padding-left: 16px;
  padding-right: 16px;
}

#page-header {
  display: flex;
  flex-flow: row wrap;
}

#logo-section {
  display: flex;
  flex-flow: row;
  justify-content: center;
  margin-top: 10px;
  margin-bottom: 10px;
}

.image-wrapper {
  /*background: red;*/
  margin-right: 25px;
}

#lasitek-logo {
  width: 100px;
  height: auto;
}

.main-title {
  font-size: 18px;
  /*background: green;*/
  color: var(--main-color);
  margin: 0;
  margin-top: 6px;
}

.horizontal-line {
  margin-top: 16px;
  width: 100%;
  height: 2px;
  background: #949494;
}

#nav-buttons {
  /*background: purple;*/
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex: 1 100%;
}

#btn-menu {
  display: none;
}

#main-content {
  position: relative;
  width: auto;
  margin-top: 32px;
}

.btn-logo {
  /*background: purple;*/
  cursor: pointer;
  z-index: 15;
  position: absolute;
  top: -5px;
  left: 5px;
  transition: all 0.5s;
}

#show-hide-button {
  /*border: 1px solid var(--main-color);*/
  padding: 8px 10px 8px 4px;
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
}

#show-hide-button[data-edit-text]::after {
  content: attr(data-edit-text);
  width: 120px;
  font-size: 14px;
  position: absolute;
  top: 7px;
  left: 40px;
  color: var(--main-color);
}

#wrap-arrow {
  width: 30px;
  height: 30px;
  background-color: gray;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
}

#arrow {
  width: 10px;
  height: 10px;
  border-bottom: 2px solid black;
  border-left: 2px solid black;
  transform: rotate(45deg);
  border-radius: 15%;
  border-color: #fcba03;
}

section.parameters {
  position: absolute;
  top: 0px;
  left: -110vw;
  width: 92vw;
  height: 87vh;
  z-index: 10;
  transition: all 0.3s;
  background-color: white;
}

#model-container {
  width: 92vw;
  height: 100%;
  position: absolute;
  top: 50px;
  left: 0px;
  /*background: black;*/
}

#btn-menu:checked ~ * section.parameters {
  left: 0px;
  height: auto;
  transition: all 0.5s;
}

#btn-menu:checked ~ * .btn-logo {
  left: calc(100vw - 70px);
  transition: all 0.5s;
}

#btn-menu:checked ~ * #arrow {
  transform: none;
  border: none;
}

#btn-menu:not(:checked) ~ * #icon-3d {
  display: none;
}

#btn-menu:checked ~ * #show-hide-button::after {
  content: none;
}

#btn-menu:checked ~ * #wrap-arrow {
  display: none;
}

#btn-menu:checked ~ * #show-hide-button[data-see3d-text]::before {
  content: attr(data-see3d-text);
  text-align: center;
  width: 150px;
  font-size: 13px;
  position: absolute;
  top: 10px;
  right: 38px;
  color: var(--main-color);
}


@media all and (min-width: 600px) {
  #lasitek-logo {
    width: 115px;
  }
  .main-title {
    margin-top: 10px;
    font-size: 20px;
  }
}

@media all and (min-width: 800px) {
  #lasitek-logo {
    width: 125px;
  }

  #nav-buttons {
    margin-top: 9px;
    flex: auto;
  }

  #logo-section {
    justify-content: flex-start;
    flex: auto;
  }
}

@media all and (min-width: 860px) {
  .btn-logo {
    display: none;
  }

  #main-content,
  #model-container,
  section.parameters {
    position: static;
  }

  section.parameters {
    width: 35%;
    height: 70vh;
    transition: none;
    background-color: #33426c;
    /* background-color: red; */
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 16px;
  }

  #model-container {
    width: 65%;
    top: 0px;
  }

  #main-content {
    display: flex;
    flex-direction: row;
  }
}

.container {
  display: flex;
  justify-content: space-between;
  border-bottom: solid;
}

.no-margin > * {
  margin: 0px;
}

.button {
  opacity: 1;
  color: #ffed00;
  cursor: pointer;
  margin: 20px 20px 0px 20px;
  padding: 5px;
  font-size: 18px;
  display: flex;
  align-items: center;
}

.color1 {
  background: var(--main-color);
}

.color2 {
  background: #707070 0% 0% no-repeat padding-box;
}

.text-color1 {
  color: #949494;
  font-size: 15px;
  cursor: text;
}

.text-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.image-placeholder {
  background: #eeeeee;
  border-radius: 10px;
  height: 90px;
  width: 90px;
}

.feedback-message {
  color: red;
  font-size: 12.4px;
  margin-left: 15px;
  margin-top: 0px;
}

.invalid-group-input {
  border: 1px solid red;
  padding: 5px;
  border-radius: 4px;
  margin-bottom: 10px !important;
  margin-left: 15px !important;
  margin-right: 15px !important;
}

.next-button {
  max-width: 380px;
  height: 30px;
  margin: auto;
}

.button-triangle--next {
  margin-left: 3px;
  width: 10px;
  height: 10px;
  border-top: 3px solid #ffed00;
  border-right: 3px solid #ffed00;
  transform: rotate(45deg);
  border-radius: 15%;
}
.mobile-button {
  position: fixed;
  bottom: 15px;
  left: 50%;
  z-index: 11;
  min-width: 90vw;
  margin-left: -45vw;
}

.desktop-button {
  display: none !important;
}

.bottom-data {
  display: flex;
  max-width: 380px;
  margin: auto;
  margin-top: 15px;
  height: 40px;
}

.floating-button {
  display: flex;
  margin-top: 15px;
}

.shopping-cart,
.back-button {
  background: #4d4d4d;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 15px;
  padding-right: 15px;
  cursor: pointer;
}

.button-triangle--back--desktop,
.button-triangle--back {
  width: 10px;
  height: 10px;
  border-bottom: 3px solid #ffed00;
  border-left: 3px solid #ffed00;
  transform: rotate(45deg);
  border-radius: 15%;
}

.button-triangle--back {
  margin-top: 3px;
}

.cost-container {
  background: #33426c;
  flex-grow: 1;
  margin-left: 10px;
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.bar,
.total {
  color: #ffed00;
}

.bar {
  margin-left: 10px;
  margin-right: 10px;
}

.eur,
.cost {
  color: white;
}

.eur {
  margin-left: 5px;
}

.app-container {
  background-color: white;
  padding-bottom: 20px;
}

.footer {
  display: none;
  justify-content: space-between;
  margin-top: 20px;
  padding-top: 30px;
  padding-bottom: 30px;
  font-size: 20px;
  padding-left: 130px;
  padding-right: 130px;
  align-items: center;
}

.footer-cost {
  display: flex;
  border-bottom: 1px solid var(--yellow);
}

.footer-cost::after {
  content: '€';
  color: var(--yellow);
}

.add-to-cart,
.total-desktop {
  color: white;
  text-transform: uppercase;
}

.add-to-cart {
  cursor: pointer;
  border: 2px solid var(--yellow);
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 15px;
  padding-bottom: 15px;
  transition: all 0.5s;
}

.add-to-cart:hover {
  background-color: var(--yellow);
}

.add-to-cart::after {
  content: 'LISÄÄ OSTOSKORIIN';
  transition: all 0.5s;
}

.add-to-cart:hover::after {
  color: black;
  font-weight: bolder;
  transition: all 0.5s;
}

.cost-desktop {
  margin-left: 30px;
  color: var(--yellow);
  margin-right: 10px;
}

@media all and (min-width: 860px) {
  .bottom-data,
  .next-button {
    margin: 0;
    margin-top: 15px;
    max-width: none;
  }

  .footer {
    display: flex;
  }

  #root {
    background-color: #33426c;
    padding-left: 40px;
    padding-right: 40px;
    padding-top: 30px;
  }
  .app-container {
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 20px;
  }

  .mobile-button {
    display: none;
  }

  .desktop-button {
    display: flex !important;
  }

  .form-container {
    background-color: white;
  }
}
