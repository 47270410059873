.loading {
  width: fit-content;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.model-loading--container {
  width: 100%;
  height: 100%;
  position: relative;
}
