.templates-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    height: 100%;
    width: 100%;
}

.templates-container h4 {
    margin: 0;
    font-weight: lighter;
    color: var(--main-color);
}

.template-container-det {
    flex: 1 1 auto;
}

.image-center {
    display: block;
    margin: auto auto;
    cursor: pointer;
    width: 100%;
    align-items: center;
    justify-content: center;
}

.selected-image {
    border: solid var(--main-color);
}